*{
  font-family: sans-serif;
}
.navbar{
  position: fixed;
  width: 100%;
  height: 5vw;
  background-color: black;
  display: flex;
  z-index: 100;
  box-shadow: 4px 4px 10px gray;
  position: fixed;
}

.company_logo{
  position: absolute;
}

.name{
  width: 15vw;
  height: 3vw;
  font-size: 3vw;
  margin-left: 4%;
}

.links{
  margin-top: 1%;
  margin-left: 58%;
  color: white;
  display: flex;
  text-decoration: none;
  font-size: 1.6vw;
}
.linksResp{
  padding: .5vw;
  margin-left: 75%;
  color: white;
  display: flex;
  text-decoration: none;
  font-size: 1.6vw;
}

.linksResp > button{
  width: 16.5vw;
  height: 9vw;
  background-color: black;
  color: blue;
  margin-left: 20%;
  border: .3vw solid blue;
  margin-top: 2vw;
  font-size: 4vw;
  border-radius: 2vw;
}

.user{
  font-size: 3.5vw;
  margin-top: 3.5vw;

}
.login{
  background-color: rgb(32, 166, 255);
  color: white;
  border: 0;
  width: 9vw;
  height: 2.5vw;
  border-radius: .2vw;
  margin-left: 4%;
  font-size: 1.3vw;
}
.franchise{
  background-color: green;
  color: white;
  border: 0;
  width: 12vw;
  height: 2.5vw;
  border-radius: .2vw;
  margin-left: 2%;
  font-size: 1.3vw;

}
.main_image{
  width: 100%;
  height: 40vw;

}
.img_box{
  overflow: hidden;
  width: 95%;
  height: 40vw;
  background-color: grey;
  border-radius: 2vw;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-left: 2.6%;
  margin-top: 7%;
  position: absolute;
  z-index: -1;
}

.schedulepickup{
  background-color: rgb(32, 166, 255);
  border: 0;
  color: white;
  height: 4vw;
  width: 27vw;
  border-radius: .4vw;
  margin-left: 38%;
z-index: 3;
margin-top: 30%;
font-size: 2vw;
font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;

}
.schedulepickup:hover{
background-color: rgb(11, 88, 140);
transition-duration: .5s;
}
.main_down{
  margin-top: 20%;
  
}
.discbox{
  height: 20vw;
  width: 30%;
  position: absolute;
  margin-left: 17%;
  
}
.disc{
 
  font-size: 2.5vw;
  font-weight: bolder;
}

.disc >span{
  color: rgb(77, 184, 255);
}

.moreinfobox{
  height: 20vw;
  width: 25%;
  font-size: 1.5vw;
  margin-left: 60%;
}

/* services */

.services_head{
  text-align: center;
  font-size: 3vw;
  font-family:sans-serif;
  font-weight: bolder;
}

.services_box{
  
  display: flex;
  width: 95%;
  height: 25vw;
  margin: 2.6%;
  background-color: rgb(151, 200, 252);
  border-radius: 2vw;
}

.serviceimg{
  height: 6vw;
  width: 8vw;
  border-radius: 50%;
 margin-left: 30%;
 display: flex;
 text-align: center;
  justify-content: center;
  align-items:center;
  background-color: white;
}

.service_ele{
  height: 20vw;
  width: 20vw;
  /* border: .2vw solid black; */
  margin: 2.3%;
  margin-left: 8%;
}

.servicename{
  display: flex;
  text-align: center;
  justify-content: center;
  align-items:center;
  font-size: 1.5vw;
  margin-top: 5%;
}
.serviceinfo{
  text-align: center;
  font-size: 1.2vw;
}
.underline{
  width:12vw;
  height: .1vw;
  background-color: black;
 margin-left: 19%;
 margin-top: 5%;
 margin-bottom: 5%;
}
/* why choose us */

.choosehead{
  font-size: 2.5vw;

  
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-top: 7%;
}

.choosebox{
  /* display: flex; */
  width: 95%;
  height: 45vw;
  margin: 2.6%;
  background-color: rgb(226, 228, 231);
  border-radius: 2vw;
  box-shadow: 2px 2px 10px gray;
}

.choose_ele{
display: flex;
text-align: center;
justify-content: center;
}


.choose_ele_row{
  height: 10vw;
  width: 35vw;
  /* border: .2vw solid black; */
  margin: 2.3%;
  margin-left: 4%;
  padding-left: 5%;
  padding-right: 5%;
 }
.chooseusimg{
  height: 7vw;
  width: 8vw;
  border-radius: 50%;
 margin-left: 23%;
 display: flex;
 text-align: center;
  justify-content: center;
  align-items:center;
  background-color: white;
  box-shadow: 2px 2px 10px gray;
  box-shadow: inset 1px 1px 10px 7px rgb(120, 207, 245) ;
  color: rgb(21, 121, 188);
}

.choose_head{
  font-size: 1.2vw;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-weight: bolder;
  font-family: sans-serif;
  margin-top: 6%;
}

.downloadapp{
  height: 50vw;
  width: 95%;
  background-color: rgb(226, 228, 231);
  border-radius: 2vw;
  margin-left: 2.6%;
  margin-top: 12%;

  box-shadow: 2px 2px 10px gray;
}

/* download */
.downloadimg{
  margin-left: 5%;
}
.phone_back{
  width: 45vw;
  height: 45vw;
  margin-top: 3%;
  margin-left: -4%;
  position: absolute;

   animation: rotate 70s linear infinite ;
}
@keyframes rotate {
      from{
        transform: rotate(0deg);
      }  
      to{
        transform: rotate(360deg);
      }
}

.phone{
  position: absolute;
  z-index: 10;
  height: 60vw;
  width: 35vw;
  margin-top: -10%;
  
}

.downloadinfo{
  margin-left: 52%;
  /* border: 1px solid black; */
  width: 44vw;
  height: 40vw;
}
.d_head{
  text-shadow: 2px 2px 4px rgb(0,0,0,0.5);
  font-size: 2.3vw;
  text-align: end;
  font-weight: bolder;
  padding:1vw ;
}
.d_head > span{
  color: rgb(32, 166, 255);
}
.d_info{
  margin-top: 6%;
  text-align: end;
  font-size: 1.8vw;
  padding: 1vw;
}

.d_info >p >span{
  color:rgb(32, 166, 255) ;
}

.download_mode{
  display: flex;
  margin-left: 19%;
  margin-top: 5%;
}
.mode{
  height: 7vw;
  width: 14vw;
  border-radius: .8vw;
  padding: 1.5vw;
  margin-left: 12%;
}

/* footer */

.footerbox{
  /* height: 30vw; */
  width: 95%;
  /* background-color: rgb(226, 228, 231); */
  border-radius: 2vw;
  margin-left: 2.6%;
   margin-top: 7%;
  /* box-shadow: 2px 2px 10px gray; */
}

.logo_box{
  position: absolute;
  margin-left: 10%;
  margin-top: 2%;
}
.logo{
  height: 7vw;
  width: 20vw;
  border-radius: .5vw;
  margin-left: 17%;
  margin-top: 3%;
}
.logo_info{
  width: 25vw;
  height: 20vw;
  font-size: 1.3vw;
  margin-left: 9%;
  margin-top: 5%;

}
.footer1{
  display: flex;
  margin-left: 45%;
  position: absolute;
  
}
.footer2{
  display: flex;
  margin-left: 60%;
  position: absolute;
  list-style: none;
}
.footer2 >ul >li{
  text-decoration: none;
  list-style: none;
  margin-top: 12%;
}
.footer3{
  display: flex;
  margin-left: 75%;
  position: absolute;
  list-style: none;
}

.footer3  >ul >li{
  text-decoration: none;
  list-style: none;
  margin-top: 12%;
}
.head{
  font-size: 2vw;
  font-weight: bolder;
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
.footer1  >ul >li{
  text-decoration: none;
  list-style: none;
  margin-top: 12%;
}
li:hover{
  cursor: pointer;
}
li{
  margin-top: 4%;
  font-size: 1.2vw;
}

@media screen and (max-width:700px){

  *{
    font-weight: bold;
    font-family: sans-serif;
  }
  .navbar{
    height: 14vw;
  }
  .img_box{
    margin-top: 15%;
  }

  .main_down{
    margin-top: 60%;
    
  }
  /* .main_image{
    width: 100%;
    height: 40vw;
  
  } */
  .img_box{
    overflow: hidden;
    width: 95%;
    height: 60vw;
    background-color: grey;
    border-radius: 2vw;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-left: 2.6%;
    margin-top: 20%;
    position: absolute;
    z-index: -1;
  }
  .main_image{
    width: 100%;
    height: 60vw;
  
  }
  .schedulepickup{
    background-color: rgb(32, 166, 255);
    border: 0;
    color: white;
    height: 9vw;
    width: 90vw;
    border-radius: .4vw;
    margin-left: 5%;
  z-index: 3;
  margin-top: 60%;
  font-size: 4vw;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  
  }
  .schedulepickup:hover{
  background-color: rgb(11, 88, 140);
  transition-duration: .5s;
  }
  

  .discbox{
    margin-top: -37%;
    height: 20vw;
    width: 90%;
    margin-left: 5%;
    
  }
  .disc{
   
    font-size: 7.5vw;
    font-weight: bolder;
  }
  
  .disc >span{
    color: rgb(77, 184, 255);
  }
  
  .moreinfobox{
   
    height: 20vw;
    width: 60%;
    font-size: 3.5vw;
    margin-left: 5%;
  }
  
  /* services */

  .services_head{
    text-align: center;
    font-size: 5vw;
    font-family:sans-serif;
    font-weight: bold;
    margin-top: 15%;
  }
  
  .services_box{
    width: 95%;
    height: 200vw;
    margin: 2.6%;
    background-color: rgb(151, 200, 252);
    border-radius: 2vw;
  }
  
  .serviceimg{
    height: 22vw;
    width: 25vw;
    border-radius: 50%;
   margin-left: 15%;
   text-align: center;
    justify-content: center;
    align-items:center;
    background-color: white;
  }
  
  .service_ele{
    height: 20vw;
    width: 50vw;
    /* border: .2vw solid black; */
    margin: 2.3%;
    display: block;
  }
  .e1{
    display: block;
    position: absolute;
    margin-left: 30%;
    margin-top: 5%;
  }
  .e2{
    display: block;
    position: absolute;
    margin-top: 68%;
    margin-left: 30%;
  }
  .e3{
    display: block;
    margin-top: 140%;
    margin-left: 30%;
  }
  
  .servicename{
    
    text-align: center;
    justify-content: center;
    align-items:center;
    font-size: 3.5vw;
    margin-top: 5%;
    margin-left: -20%;
    font-weight: bolder;
  }
  .serviceinfo{
    text-align: center;
    font-size: 3.2vw;
    margin-left: -13%;
  }
  .underline{
    width:12vw;
    height: .2vw;
    background-color: black;
   margin-left: 28%;
   margin-top: 5%;
   margin-bottom: 4%;

  }

  /* choose us */
 
  .choosehead{
    font-size: 5.5vw;
  
    
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-top: 10%;
  }
  .choosebox{
    /* display: flex; */
    width: 95%;
    height: 100vw;
    margin: 2.6%;
    background-color: rgb(226, 228, 231);
    border-radius: 2vw;
    box-shadow: 2px 2px 10px gray;
  }
  
  .choose_ele{
  display: flex;
  text-align: center;
  justify-content: center;
  }
  
  
  .choose_ele_row{
    height: 10vw;
    width: 35vw;
    /* border: .2vw solid black; */
    margin: 2.3%;
    margin-left: 3%; 
     padding-left: 5%;
    padding-right: 5%;
    margin-top: 15%;
   }
  .chooseusimg{
    height: 10vw;
    width: 10vw;
    border-radius: 50%;
   margin-left: 20%;
   display: flex;
   text-align: center;
    justify-content: center;
    align-items:center;
    background-color: white;
    box-shadow: 2px 2px 10px gray;
    box-shadow: inset 1px 1px 10px 7px rgb(120, 207, 245) ;
    color: rgb(21, 121, 188);
    margin-bottom: 10%;
   
  }
  /* .reserviceimg{
    height: 14vw;
    width: 14vw;
    border-radius: 50%;
    background-color: white;
   

  } */
  
  .choose_head{
    font-size: 2vw;
    width: 20vw;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-weight: bolder;
    font-family: sans-serif;
  }

  /* download app */

  .downloadapp{
    height: 155vw;
    width: 95%;
    background-color: rgb(226, 228, 231);
    border-radius: 2vw;
    margin-left: 2.6%;
    margin-top: 12%;
  
    box-shadow: 2px 2px 10px gray;
  }
  
  /* download */

  .downloadimg{
    margin-left: 19%;
    display: flex;
  }
  .phone_back{
    width: 75vw;
    height: 75vw;
    margin-top: 10%;
    margin-left: -9%;
    position: absolute;
  
     animation: rotate 70s linear infinite ;
  }
  @keyframes rotate {
        from{
          transform: rotate(0deg);
        }  
        to{
          transform: rotate(360deg);
        }
  }
  
  .phone{
    position: absolute;
    z-index: 10;
    height: 120vw;
    width: 70vw;
    margin-top: -25%;
    
  }
  
  .downloadinfo{
    margin-left: 7%;
    text-align: center;
    /* border: 1px solid black; */
    width: 80vw;
    height: 40vw;
    margin-top: 95%;
  }

  .d_head{
    text-shadow: 2px 2px 4px rgb(0,0,0,0.5);
    font-size: 5.3vw;
    display: flex;
    text-align: center;
    justify-content: center;
    font-weight: bolder;
    padding:1vw ;
    
  }
  .d_head > span{
    color: rgb(32, 166, 255);
  }
  .d_info{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5%;
    text-align: center;
    font-size: 2.8vw;
    padding: 1vw;
   
  }
  
  .d_info >p >span{
    color:rgb(32, 166, 255) ;
  }
  
  .download_mode{
    display: flex;
    margin-left: 16%;
    margin-top: 5%;
  }
  .mode{
    height: 10vw;
    width: 25vw;
    border-radius: .8vw;
    padding: 1.5vw;
    margin-left: 4%;
  }
  

  /* footer */

  .footerbox{
    /* height: 30vw; */
    width: 95%;
    /* background-color: rgb(226, 228, 231); */
    border-radius: 2vw;
    margin-left: 2.6%;
     margin-top: 7%;
    /* box-shadow: 2px 2px 10px gray; */
  }
  
  .logo_box{
    position: absolute;
    margin-left: 10%;
    margin-top: 2%;
  }
  .logo{
    height: 10vw;
    width: 28vw;
    border-radius: .5vw;
    margin-left: -4%;
    margin-top: 3%;
  }
  .logo_info{
    width: 35vw;
    height: 20vw;
    font-size: 2.3vw;
    margin-left: -3%;
    margin-top: 5%;
  
  }
  .footer1{
    display: flex;
    margin-left: 6%;
    position: absolute;
    margin-top: 35%;
    
  }
  .footer2{
    display: flex;
    margin-left: 50%;
    margin-top: 35%;
    position: absolute;
    list-style: none;
  }
  .footer2 >ul >li{
    text-decoration: none;
    list-style: none;
    margin-top: 12%;
  }
  .footer3{
    display: flex;
    margin-left: 50%;
    position: absolute;
    list-style: none;
  }
  
  .footer3  >ul >li{
    text-decoration: none;
    list-style: none;
    margin-top: 12%;
  }
  .head{
    font-size: 4vw;
    margin-left: -5%;
    font-weight: bolder;
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  }
  .footer1  >ul >li{
    text-decoration: none;
    list-style: none;
    margin-top: 12%;
  }
  li:hover{
    cursor: pointer;
  }
  li{
    margin-top: 4%;
    font-size: 2.4vw;
  }
  
}

.namedrawer{
  position: absolute;
  display: flex;
  margin-left: 50%; 
}