/* ProgressBar.css */

/* .progress-bar {
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin: 0 auto;
  margin-top: 3%;
  margin-left: 10%;
}
.bars{
  display: flex;
}

.step {
  width: 2vw;
  height: 2vw;
  border: 2px solid #ccc;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  font-size: 16px;
  font-size: 1vw;
}

.step.completed {
  background-color: #00cc00; 
  color: #fff; 
}
.lines{

  display: flex;
}
.progressname{
  font-size: 1.1vw;
  display: flex;
}
.step1{
  display: flex;
  margin-left: 7%;
}
.step2{
  margin-left: 18%;
}
.step3{
  margin-left: 18%;
}
.step4{
  margin-left: 20.5%;
}
.line {
  display: flex;
  width:23vw;
  height: .1vw;
  background-color: #ccc;
  transition: background-color 0.3s ease-in-out;
}

.line.completed {
  background-color: #00cc00; 
} */

/* ProgressBar.css */
.progress-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin: 0 auto;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ccc; 
  color: #fff; 
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold; 
  border: 2px solid transparent;

}

.completed .circle {
  background-color: #4caf50; /* Completed circle color (green) */
  border-color: #4caf50;
}

.completed .circle span {
  font-size: 24px;
}

.line {
  width: 100%; 
  height: 2px; 
  background-color: #ccc; 
  margin-left: 270%;
  margin-top: -4%;
}

.step-name {
  margin-top: 5%;
}


.prevbtn{
  font-size: 2vw;
  border-radius: .4vw;
  height: 4vw;
  width: 10vw;
background-color: white;
  color: rgb(14, 85, 238);
  margin-right: 2%;
  font-weight: lighter;
  margin-left: 5%;
}
.nextbtn{
  font-size: 2vw;
  border-radius: 1vw;
  height: 4vw;
  width: 10vw;
  background-color: rgb(0, 81, 255);
  color: white;
  font-weight: lighter;
  margin-left: 32%;
}
.datebox{
  overflow: hidden;
  margin-top: 6%;
  margin-left: 11%;
  height: 25vw;
  width: 80%;
  border-radius: 1vw;
  background-color: rgb(255, 255, 255);
  box-shadow: 2px 2px 10px rgb(171, 171, 171);
}
.datehead{
  text-align: center;
  width: 100%;
  height: 7vw;
  font-size: 2.5vw;
  color: white;
  background-color: rgb(0, 81, 255);
}
.dates{
  list-style: none;
  display: flex;
  margin-left: -2%;
}
li{
  padding: 1%;
  margin-left: -.5%;
}
 .li{
  font-size: 1.5vw;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 10vw;
  height: 10vw;
  background-color: rgb(236, 236, 236);
  border-radius: .5vw;
  box-shadow: 2px 2px 5px rgb(171, 171, 171);

}

.timebox{
  overflow: hidden;
  margin-top: 3%;
  margin-left: 11%;
  height: 35vw;
  width: 80%;
  border-radius: 1vw;
  background-color: rgb(255, 255, 255);
  box-shadow: 2px 2px 10px rgb(171, 171, 171);
}
.timehead{
  text-align: center;
  width: 100%;
  height: 7vw;
  font-size: 2.5vw;
  color: white;
  background-color: rgb(0, 81, 255);
}

.litimes{
  margin-top: 20%;
  font-size: 1.5vw;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 10vw;
  height: 10vw;
  background-color: rgb(236, 236, 236);
  border-radius: .5vw;
  box-shadow: 2px 2px 5px rgb(171, 171, 171);

}

.times{
  list-style: none;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(7,1fr);
  gap: 10px;
  margin-left: -2%;
}
.navbtn{
  margin-top: 3%;
  margin-left: 22%;
  margin-bottom: 3%;
}

@media screen and (max-width:700px){

  /* ProgressBar.css */
/* .progress-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;

  margin-top: 3%;
  margin-left: 10%;
}

.step {
  width: 9vw;
  height:8vw;
  border: 2px solid #ccc;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  font-size: 3vw;
}

.step.completed {
  background-color: #00cc00;
  color: #fff; 
}
.lines{

  display: flex;
}
.progressname{
  font-size: 2.3vw;
  display: flex;
}
.step1{
  margin-left: 5%;
}
.step2{
  margin-left: 7%;
}
.step3{
  margin-left: 7%;
}
.step4{
  margin-left: 11%;
}
.line {
  display: flex;
  width:23vw;
  height: .1vw;
  background-color: #ccc;
  transition: background-color 0.3s ease-in-out;
}

.line.completed {
  background-color: #00cc00; 
} */

.progress-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin: 0 auto;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ccc; 
  color: #fff; 
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-weight: bold; 
  border: 2px solid transparent;

}
.circle4{
  display:flex;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ccc; 
  color: #fff; 
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-weight: bold; 
  border: 2px solid transparent;
  margin-left: 50%;
  margin-top:-4%;
 
}

.completed .circle {
  background-color: #4caf50; /* Completed circle color (green) */
  border-color: #4caf50;
}

.completed .circle span {
  font-size: 24px;
}

.line {
  width: 5%; 
  height: 2px; 
  background-color: #ccc; 
  margin-left: 140%;
  margin-top: -20%;
}


.step-name {
  margin-top: 30%;
  font-size: 2.3vw;
}

.step4{
  margin-top: 23%;
  font-size: 2.3vw;
  margin-left: 50%;
}

.prevbtn{
  font-size: 5.5vw;
  border-radius: .4vw;
  height: 10vw;
  width: 25vw;
background-color: white;
  color: rgb(14, 85, 238);
  margin-right: 2%;
  font-weight: lighter;
  margin-left: 13%;
}
.nextbtn{
  font-size: 5.5vw;
  border-radius: 1vw;
  height: 10vw;
  width: 25vw;
  background-color: rgb(0, 81, 255);
  color: white;
  font-weight: lighter;
  margin-left: 20%;
}

.datebox{
  overflow: hidden;
  margin-top: 15%;
  margin-left: 5%;
  height: 55vw;
  width: 90%;
  border-radius: 3vw;
  background-color: rgb(255, 255, 255);
  box-shadow: 2px 2px 10px rgb(171, 171, 171);
  display: grid;
  grid-template-columns: repeat(4,1fr);
  gap: 10px;
}

.datehead{
  text-align: center;
  width: 560%;
  height: 10vw;
  font-size: 5.5vw;
  color: white;
  background-color: rgb(0, 81, 255);
}
.dates{ 
  margin-top: 18%;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(4,1fr);
  margin-left: -30%;
 
}
li{
  padding: 1%;
  margin-left: -.5%;
}
 .li{
  font-size: 2.5vw;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 16vw;
  height: 15vw;
  background-color: rgb(255, 255, 255);
  border-radius: 3vw;
  box-shadow: 2px 2px 5px rgb(171, 171, 171);

}

.timebox{
  overflow: hidden;
  margin-top: 8%;
  margin-left: 5%;
  height: 60vw;
  width: 90%;
  border-radius: 3vw;
  background-color: rgb(255, 255, 255);
  box-shadow: 2px 2px 10px rgb(171, 171, 171);
}
.timehead{
  text-align: center;
  width: 100%;
  height: 10vw;
  font-size: 5.5vw;
  color: white;
  background-color: rgb(0, 81, 255);
}

.times{
  margin-top: 1%;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(4,1fr);
  gap: 10px;
  margin-left:-6%;
}

.litimes{
  font-size: 2.5vw;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 17vw;
  height: 15vw;
  background-color: rgb(255, 255, 255);
  border-radius: 2vw;
  box-shadow: 2px 2px 5px rgb(171, 171, 171);

}
.navbtn{
  margin-top: 10%;
  margin-left: 2%;
  margin-bottom: 3%;
}
  
}