*{
    padding: 0;
    margin: 0;
    border: 0;
}
table {
    width: 100%;
}
th,td {
    font-size: 1.3vw;
    text-align: left;
}
tr:hover{
    background-color:rgb(225, 236, 252) ;
}

/* Responsive styles */


.history{
    color: black;
    font-size: 2.5vw;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-weight: bolder
    

}
.histNavBox{
    margin-left: 25%;
}
.historyNav{
    width: 20%;
    height:3vw ;
    font-size: 2vw;
    margin-left: 10%;
    border-radius: .5vw;
    background-color: white;
    color: rgb(70, 70, 248);
}
.userbox{
    /* z-index: -1; */

    margin-left: 30%;
    width: 67%;

 
    border-radius: 1vw;
    box-shadow: 2px 2px 10px rgb(116, 116, 116);
  position: absolute;
  margin-top: 8%;
  margin-left:25%;
  margin-top:2%;
  background-color:white
}
.user_sidebar{
    margin-left: 2%;
    margin-top: 8%;
    width: 23%;
    height: 32vw;
    background-color: rgb(222, 222, 222);
    box-shadow: 2px 2px 10px rgb(116, 116, 116);
  position: absolute;
  border-radius: .5vw;

}

.userlinks{
    height: 4vw;
    width: 90%;
    font-size: 1.9vw;
    font-family: sans-serif;
     
    color: rgb(104, 104, 104);
     margin-top: 10%;
     margin-left: 20%;
     border-radius: 1vw;
}
.orderbox{
   
    height: auto;
    width: 75%;
    margin-bottom: 2%;
    margin-top: 5%;
    margin-left: 12%;
    
  
    border: 1px solid rgb(65, 65, 255);
    background-color: rgb(225, 236, 252);
    border-radius: 0%;
}

.orderdata{
    display: flex;
    text-align: end;
    align-items: end;
    justify-content: end;
    color: blue;
}
.order_head{
    color: green;
    font-size: 2vw;
    font-weight: bolder;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.cancel_order{
    background-color: white;
    border:1px solid red;
    color: red;
    border-radius: .3vw;
    font-family: sans-serif;
    font-size: 1.5vw;
    height: 3vw;
    width: 20vw;
    box-shadow: 2px 2px 10px rgb(109, 140, 189);
}
.cancel_order:hover{
    
    background-color: red;
    color: white;
    transition-duration: .5s;
}
.pending{
    border-radius: .3vw;
    font-family: sans-serif;
    font-size: 1.5vw;
    height: 3vw;
    width: 20vw;
    box-shadow: 2px 2px 10px rgb(109, 140, 189);
    background-color: rgb(75, 75, 255);
    color: white;
}

/* profile */

.profilebox{
    position: absolute;
    height: 45vw;
    width: 67%;
    
    margin-top: 8%;
    border-radius: .5vw;
    margin-left: 30%;
    box-shadow: 2px 2px 12px rgb(145, 145, 145);
}

.avatar{
    height: 10vw;
    width: 10vw;
    border-radius: 50%;
    background-color: rgb(95, 155, 155);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align:center ;
    font-size: 8vw;
    margin-left: 40%;
    margin-top: 3%;
    color: white;
}

.user_info{
    height: 10vw;
    width: 50%;
  
    margin-top: 3%;
    margin-left: 5%;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 2vw;
}

.input-container {
    position: relative;
    width: 80%;

}

.input-label {
    position: absolute;
    top: -10px;
    left: 15%;
    transform: translateX(-50%);
    background-color: white;
    padding: 0 10px;
    color: black;
    font-size: 1.2vw;
    

}

.text-input {
    width: 210%;
    padding: .5vw;
    border: none;
    border: 1px solid #ccc;
    outline: none;
    font-size: 1.5vw;
    margin-top: 5%;
}
/* settings */

.settingsbox{
    position: absolute;
    height: 45vw;
    width: 67%;
     margin-left: 30%;
     margin-top: 8%;
    box-shadow: 2px 2px 10px rgb(145, 145, 145);
    border-radius: 1vw;
}

.settingshead{
    display: flex;
    text-align: center;
    font-size: 2vw;
    margin-left: 40%;
    margin-top: 5%;
    font-weight: bolder;
}

.addressSettings{
    width: 85%;
    height: 3.5vw;
    border: 1px solid blue;
    font-size: 2vw;
    border-radius: .3vw;
    margin-left: 7.5%;
    text-align: center;
    margin-top: 5%;
    color: blue;
    font-weight: bolder;

}

.addressSettings:hover{
    background-color: blue;
    color: white;
    font-weight: bolder;
    cursor: pointer;
    transition-duration: .5s;
}

.orderStatusBtn{
    width: 192%;
    height: 3vw;
    font-size: 2vw;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-top: 2%;
}

.statusHead{
    font-size: 2vw;
    font-weight: bolder;
    display: flex;
    text-align: center;
    align-items:center;
    justify-content: center;

}

.statusbox{
    margin-left:8% ;

}
.statusbtns{
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 90%;
    height: 2vw;
    font-size: 1.5vw;
    border-radius: .4vw;
    background-color: rgb(77, 77, 255);
    color: white;
    margin-top: 3%;
}


@media screen and (max-width:700px){

table {
    width: 100%;
}
th,td {
    font-size: 1.3vw;
    text-align: left;
}
tr:hover{
    background-color:rgb(225, 236, 252) ;
}

  
    
    /* Responsive styles */   
    .history{
        color: black;
        font-size: 5vw;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        font-weight: bolder
        
    
    }
    .histNavBox{
        margin-left: 25%;
    }
    .historyNav{
        width: 20%;
        height:3vw ;
        font-size: 3vw;
        margin-left: 10%;
        border-radius: .5vw;
        background-color: white;
        color: rgb(70, 70, 248);
    }
    .userbox{
    
        margin-left:20%;
        width: 70%;
    
     
        border-radius: 1vw;
        box-shadow: 2px 2px 10px rgb(116, 116, 116);
      position: absolute;
      margin-top: 10%;
    }
    .user_sidebar{
        margin-left: 2%;
        margin-top: 20%;
        width: 23%;
        height: 70vw;
        background-color: rgb(222, 222, 222);
        box-shadow: 2px 2px 10px rgb(116, 116, 116);
      position: absolute;
      border-radius: .5vw;
    
    }
    
    .userlinks{
        height: 4vw;
        width: 90%;
        font-size: 1.9vw;
        font-family: sans-serif;
         
        color: rgb(104, 104, 104);
         margin-top: 10%;
         margin-left: 20%;
         border-radius: 1vw;
    }

    .td{
        font-size: 2vw;
        margin-right: 8%;
        margin-top: 0%;

    }
  
    .orderbox{
       
        height: 52vw;
        width: 125%;
        margin-top: 5%;
        margin-left: -24%;
        border-radius:1vw;
        display: flex;
         font-size: 3vw;
        border: 1px solid rgb(65, 65, 255);
        background-color: rgb(225, 236, 252);
    }
    
    .orderdata{
        display: flex;
        text-align: end;
        align-items: end;
        justify-content: end;
        color: blue;
        font-size: 2vw;
    }
    .order_head{
        color: green;
        font-size: 2vw;
        font-weight: bolder;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
    
    .cancel_order{
        background-color: white;
        border:1px solid red;
        color: red;
        border-radius: .3vw;
        font-family: sans-serif;
        font-size: 1.5vw;
        height: 3vw;
        width: 20vw;
        box-shadow: 2px 2px 10px rgb(109, 140, 189);
    }
    .cancel_order:hover{
        
        background-color: red;
        color: white;
        transition-duration: .5s;
    }
    .pending{
        border-radius: .3vw;
        font-family: sans-serif;
        font-size: 1.5vw;
        height: 3vw;
        width: 20vw;
        box-shadow: 2px 2px 10px rgb(109, 140, 189);
        background-color: rgb(75, 75, 255);
        color: white;
    }
    
    /* profile */
    
    .profilebox{
        position: absolute;
        height: 55vw;
        width: 67%;
        
        margin-top: 20%;
        border-radius: .5vw;
        margin-left: 30%;
        box-shadow: 2px 2px 12px rgb(145, 145, 145);
    }
    
    .avatar{
        height: 10vw;
        width: 10vw;
        border-radius: 50%;
        background-color: rgb(95, 155, 155);
        display: flex;
        justify-content: center;
        align-items: center;
        text-align:center ;
        font-size: 8vw;
        margin-left: 40%;
        margin-top: 3%;
        color: white;
    }
    
    .user_info{
        height: 10vw;
        width: 50%;
      
        margin-top: 3%;
        margin-left: 5%;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 2vw;
    }
    
    .input-container {
        position: relative;
        width: 80%;
    
    }
    
    .input-label {
        position: absolute;
        top: 1px;
        left: 17%;
        transform: translateX(-50%);
        background-color: white;
        padding: 0 10px;
        color: black;
        font-size: 1.2vw;
 
    
    }
    
    .text-input {
        width: 210%;
        padding: .5vw;
        border: none;
        border: 1px solid #ccc;
        outline: none;
        font-size: 2vw;
        margin-top: 5%;
    }
    /* settings */
    
    .settingsbox{
        position: absolute;
        height: 45vw;
        width: 67%;
         margin-left: 30%;
         margin-top: 20%;
        box-shadow: 2px 2px 10px rgb(145, 145, 145);
        border-radius: 1vw;
    }
    
    .settingshead{
        display: flex;
        text-align: center;
        font-size: 5vw;
        margin-left: 30%;
        margin-top: 5%;
        font-weight: bolder;
    }
    
    .addressSettings{
        width: 85%;
        height: 5vw;
        border: 1px solid blue;
        font-size: 3vw;
        border-radius: 1vw;
        margin-left: 7.5%;
        text-align: center;
        margin-top: 5%;
        color: blue;
        font-weight: bolder;
    
    }
    
    .addressSettings:hover{
        background-color: blue;
        color: white;
        font-weight: bolder;
        cursor: pointer;
        transition-duration: .5s;
    }

    .orderStatusBtn{
        width: 190%;
        height: 6vw;
        font-size: 4vw;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        margin-top: 2%;
    }
    
    .statusHead{
        font-size: 2vw;
        font-weight: bolder;
        display: flex;
        text-align: center;
        align-items:center;
        justify-content: center;
    
    }
    
    .statusbox{
        margin-left:8% ;
    
    }
    .statusbtns{
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        width: 90%;
        height: 2vw;
        font-size: 1.5vw;
        border-radius: .4vw;
        background-color: rgb(77, 77, 255);
        color: white;
        margin-top: 3%;
    }
    

    
}

