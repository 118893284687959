.msgbox{
    margin-top: 8%;
    margin-left: 33%;
    position: absolute;
    padding: 2vw;
  
    height: 30vw;
    border-radius: 1vw;
    box-shadow: 2px 2px 10px 10px gray;
}
textarea{
    margin-top: 10%;
}
.send{
    position: absolute;
  margin-top: 65%;
  width: 90%;
  height: 3vw;
  font-size: 2vw;
  margin-left: -89%;
}
.head{
    font-size: 2vw;
    position: absolute;
    margin-top: 3%;
    margin-left: 39%;
}
.topbar{
   
    margin-top: 0%;
    font-size: 2vw;
    font-weight: bold;
    color: red;
    position: absolute;
    margin-left: 2%;
  
      }
@media screen and (max-width:700px){
    .topbar{
  margin-left: 97%;
  margin-top: 2%;
  font-size: 5vw;
  font-weight: bold;
  color: red;
  position: absolute;

    }
    .msgbox{
        margin-top: 60%;
        margin-left: 0%;
        position: absolute;
        padding: 2vw;
      
        height: 90vw;
        border-radius: 1vw;
        box-shadow: 2px 2px 10px 10px gray;
    }
    textarea{
        margin-top: 10%;
        
    }
    .send{
        position: absolute;
      margin-top: 1%;
      width: 96%;
      height: 10vw;
      font-size: 6vw;
      margin-left: 0%;
    }
    .head{
        font-size: 5.5vw;
        position: absolute;
        margin-top: 45%;
        margin-left: 28%;
      
    }
}