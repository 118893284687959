*{
    font-family: sans-serif;
}
  
.addressbox{
    overflow: hidden;
  margin-top: 6%;
  margin-left: 11%;
  height: 30vw;
  width: 80%;
  border-radius: 1vw;
  background-color: rgb(255, 255, 255);
  box-shadow: 2px 2px 10px rgb(171, 171, 171);
}

.address_head{
    text-align: center;
    width: 100%;
    height: 5vw;
    font-size: 2.5vw;
    color: white;
    background-color: rgb(0, 81, 255);
}
.selectaddress{
    font-size: 1vw;
    margin-top: 6%;
    margin-left: 5%;
    width: 15vw;
    height: 3vw;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: rgb(0, 81, 255);
    border: .1vw solid rgb(0, 81, 255);
    font-weight: bolder;
    border-radius: .5vw;
   
}
.getCurrentLocation{
    font-size: 1.1vw;
    margin-top: 1%;
    margin-left: 5%;
    width: 15vw;
    height: 3vw;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: rgb(0, 81, 255);
    border: .1vw solid rgb(0, 81, 255);
    font-weight: bolder;
    border-radius: .5vw;
}
.getCurrentLocation:hover{
    cursor: pointer;
    background-color:rgb(0, 81, 255) ;
    color: white;
    transition-duration: .4s;

}
.address{
    margin-left: 4%;
    margin-top: 1%;
    font-size: 1.3vw;
    color: rgb(0, 81, 255);
    position: absolute;
    border: .1vw solid rgb(7, 57, 194);
    border-radius:1vw;
    padding: 1%;
    width: 70%;
    background-color: rgb(212, 232, 253);

}

.saveAddressbtn{
    margin-left: 93%;
    margin-top: 5%;
    
}
.selectaddress:hover{
    background-color:rgb(0, 81, 255) ;
    color: white;
    transition-duration: .4s;
}

.address_dialoge{
    overflow: hidden;
    height: 70vw;
    width: 40%;
    margin-left: 31%;
    border-radius: .5vw;
    box-shadow:2px 2px 12px rgb(181, 181, 181) ;
    margin-top: 5%;

}
.dialogetop{
   height: 3vw;
   width: 100%;
   background-color: rgb(0, 89, 255);
   color: white;
   text-align: center;
   justify-content: center;
   align-items: center;
   display: flex;
   font-size: 2vw;

}
.radiohead{
    display: flex;
    margin-top: 5%;
  color: rgb(0, 89, 255);
  font-size: 1.5vw;
  margin-left: 4%;
 
}
.radio-group{
    margin-left: 2%;
    margin-top: 3%;
    font-size: 1.3vw;
}
.radiolabel{
    margin: 2%;

}

 /* TextInput.css */
 /* .input-container {
     width: 80%;
     margin: 0 auto;
     margin-top: 5%;
     margin-left: 2%;
    }

.input-wrapper {
    position: relative;
    margin-bottom: 20px;
}

.text-input {
    border: none;
    outline: none;
    font-size: 2vw;
    margin-top: 2%;
    width: 20vw;
    border-bottom: 2px solid #ccc;
    
}

.input-label {
    position: absolute;
    left: 0;
    pointer-events: none;
    transition: transform 0.3s, font-size 0.3s, top 0.3s;
    font-size: 1.5vw;
    color: #888;
    top: 10px;
    margin-left: 6%;
    margin-top: 10%;
}

.text-input:focus + .input-label,
.text-input:valid + .input-label {
    transform: translateY(-20px) translateX(-60px);
    font-size: 12px;
    font-size: 1vw;
    color: rgb(0, 89, 255);
    top: -10px;
} */
.input-container {
    width: 80%;
    margin: 0 auto;
    margin-left: 5%;
}

.input-wrapper {
    position: relative;
    margin-bottom: 20px;
}

.text-input {
    width: 100%;
    padding: 8px 0;
    border-bottom: 2px solid #ccc;
    border: none;
    outline: none;
}

.input-label {
    position: absolute;
    left: 0;
    pointer-events: none;
    transition: transform 0.3s, font-size 0.3s, top 0.3s;
    font-size: 16px;
    color: #888;
    top: 10px;
    margin-top: 10%;
    margin-left: 5%;
    display: flex;
    text-align: start;
}

.text-input:focus + .input-label,
.text-input:valid + .input-label {
    transform: translateY(-10px) translateX(-80px);
    font-size: 12px;
    top: -20px;
    color: blue;
}
  
  .btn{
    height: 3vw;
    width: 15vw;
    border: .1vw solid rgb(0, 110, 255);
    color: rgb(0, 110, 255);
    background-color: white;
    border-radius: .4vw;
    margin-left: 8%;
    
    margin-top: 5%;
  }

  .save{
    background-color: rgb(0, 110, 255);
    color: white;
  }

  
  @media screen and (max-width:700px) {
    .addressbox{
        overflow: hidden;
      margin-top: 15%;
      margin-left: 0%;
      height: 60vw;
      width: 100%;
      border-radius: 2vw;
      background-color: rgb(255, 255, 255);
      box-shadow: 2px 2px 10px rgb(171, 171, 171);
    }
    
    .address_head{
        text-align: center;
        width: 100%;
        height: 8vw;
        font-size:5vw;
        color: white;
        background-color: rgb(0, 81, 255);
    }
    .selectaddress{
        font-size: 4vw;
        margin-top: 15%;
        margin-left: 5%;
        width: 45vw;
        height: 10vw;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: rgb(0, 81, 255);
        border: .1vw solid rgb(0, 81, 255);
        font-weight: lighter;
        border-radius: .8vw;
        position: absolute;
        
    }

    .getCurrentLocation{
        font-size: 4vw;
        margin-top: 15%;
        margin-left: 51%;
        width: 45vw;
        height: 10vw;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: rgb(0, 81, 255);
        border: .1vw solid rgb(0, 81, 255);
        /* font-weight: bolder; */
        font-weight: lighter;
        border-radius: .5vw;
    }
    .getCurrentLocation:hover{
        cursor: pointer;
        background-color:rgb(0, 81, 255) ;
        color: white;
        transition-duration: .4s;
    
    }
    .address{
        height: 24vw;
        width: 85%;
        margin-left: 5%;
        font-size: 4vw;
        margin-top: 2%;
        
    }
    
    .saveAddressbtn{
        margin-left: 93%;
        margin-top: 10%;
        
    }
    .selectaddress:hover{
        background-color:rgb(0, 81, 255) ;
        color: white;
        transition-duration: .4s;
    }
    
    .address_dialoge{
        overflow: hidden;
        height: 160vw;
        width: 100%;
    margin-left: 0%;
        border-radius: 2vw;
        box-shadow:2px 2px 12px rgb(181, 181, 181) ;
        margin-top: 7%;
    
    }
    .dialogetop{
       height: 12vw;
       width: 100%;
       background-color: rgb(0, 89, 255);
       color: white;
       text-align: center;
       justify-content: center;
       align-items: center;
       display: flex;
       font-size: 6vw;
       font-weight: lighter;
    
    }
    .radiohead{
        display: flex;
        margin-top: 5%;
      color: rgb(0, 89, 255);
      font-size: 5vw;
      margin-left: 4%;
     
    }
    .radio-group{
        margin-left: 2%;
        margin-top: 3%;
        font-size: 5vw;
    }
    .radiolabel{
        margin: 2%;
    
    }
    
    /* CustomInputs.css */
    /* .input-container {
        width: 80%;
        margin: 0 auto;
        margin-top: 5%;
       }
   
   .input-wrapper {
       position: relative;
  
   }
   
   .text-input {
       width: 100%;
       padding: 8px 0;
       border: none;
       border-bottom: 2px solid #ccc;
       outline: none;
       font-size: 4vw;
       margin-top: 2%;
   }
   
   .input-label {
       position: absolute;
       margin-top: 7%;
       left: 0;
       pointer-events: none;
       transition: transform 0.3s, font-size 0.3s, top 0.3s;
       color: #888;
       font-size: 100vw;
    
   }
   
   .text-input:focus + .input-label,
   .text-input:valid + .input-label {
       transform: translateY(-5px);
       font-size: 12px;
       font-size: 3vw;
       color: rgb(0, 89, 255);
       top: -10px;
   } */

   .input-container {
    width: 80%;
    margin: 0 auto;
    margin-left: 5%;
}

.input-wrapper {
    position: relative;
    margin-bottom: 20px;
}

.text-input {
    width: 100%;
    padding: 8px 0;
    border-bottom: 2px solid #ccc;
    border: none;
    outline: none;
}

.input-label {
    display: flex;
    position: absolute;
    left: 0;
    pointer-events: none;
    transition: transform 0.3s, font-size 0.3s, top 0.3s;
    color: #888;
    top: 10px;
    margin-top: 5%;
    margin-left: 5%;
    display: flex;
    text-align: start;
}

.text-input:focus + .input-label,
.text-input:valid + .input-label {
    transform: translateY(-10px) translateX(-80px);
    font-size: 12px;
    top: -20px;
    color: blue;
}
      
      .btn{
        height: 7vw;
        width: 40vw;
        border: .1vw solid rgb(0, 110, 255);
        color: rgb(0, 110, 255);
        background-color: white;
        border-radius: .4vw;
        margin-left: 7%;
        font-size: 4vw;
        margin-top: 5%;
        position: absolute;
      }
      .cancelAddress{
        margin-left: 9%;
        border: .1vw solid rgb(255, 0, 55);
        color:rgb(255, 0, 55) ;
      }
      
      .save{
        background-color: rgb(0, 110, 255);
        color: white;
        margin-left: 53%;
      }
    
  }