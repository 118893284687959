.customerbox{
    height: 8vw;
    width: 95%;
    background-color: white;
  
    border-radius: 1vw;
    margin: 2%;
    box-shadow: 2px 2px 10px rgb(112, 112, 112);

}
.namebox{
    width: 6vw;
    height: 6vw;
    border-radius: 10%;
    font-size: 4.5vw;
    background-color: aqua;
    color: black;
    font-family: sans-serif;
    position: absolute;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-top: 1%;
    margin-left: 2%;
}
.infobox{
    margin-left: 20%;
    margin-top: 3%;
}
.infoname{
    font-size: 2vw;
    color: black;
    font-family: sans-serif;
}
.infoemail{
    font-size: 2vw;
    color: black;
    font-family: sans-serif;
}
